import React from "react";
import "./Nav.scss";
import chordCashLogo from "../../assets/images/chordCash-logo.png";
import { HOME } from "../../routes/constants";

function Nav() {
	return (
		<div className="nav">
			<div className="nav-content">
				<a href={HOME} alt="" className="header-link">
					<img src={chordCashLogo} alt="" className="nav-logo-img" />
				</a>
			</div>
		</div>
	)
}

export default Nav;